exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-add-funds-jsx": () => import("./../../../src/pages/add-funds.jsx" /* webpackChunkName: "component---src-pages-add-funds-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-product-agreement-jsx": () => import("./../../../src/pages/product-agreement.jsx" /* webpackChunkName: "component---src-pages-product-agreement-jsx" */),
  "component---src-pages-product-jsx": () => import("./../../../src/pages/product.jsx" /* webpackChunkName: "component---src-pages-product-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */)
}

